html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #fdfdfd;
  margin: 0px;
}

@font-face {
  font-family: "Rust";
  src: url('./fonts/IntroRust-Base.ttf') format("truetype");
}

h1 {
  font-family: "Rust";
  color:#3a4387;
}

h2{
  /* font-family: "Rust"; */
  color:#3a4387;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

h3{
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

p{
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}


.container {
  margin: 80px auto 40px auto;
  /* max-width: 1200px; */
}

a {
  text-decoration: none;
}
/* a:link{color:#3a4387} */
/* a:active{color:inherit} */
/* a:visited{color:inherit} */
/* a:hover{color:inherit} */


li {
  list-style-type: none;
}

.button-capsule {
  border-radius: 500px;
}

* {
  /* outline: 1px solid red; */
}

.center {
text-align: center;
height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-container {
  margin: 10px;
  padding:0px;
  align-items: center;
  justify-content: space-between
}

.nav-container img {
  /* height: 70px;
  box-shadow:0px 0.5px 2px;
  border-radius:50%; */
  /* margin: 0px 20px 0px 0px; */
}

.side-by-side-textfield {
  display: flex;
  gap:10px;
  width:100%;
}

.side-by-side-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.side-by-side-info {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.welcome-footer .MuiButton-text {
  color: white;
}

.welcome .MuiButton-text {
  color: white;
}

.css-1xnaaoc-MuiGrid-root>.MuiGrid-item{
  padding-top:0px
}

.css-csmqbv-MuiPaper-root-MuiDialog-paper{
  border-radius: 15px;
}




.fade-appear {
  opacity: 0.0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 600ms ease-in;
}

.quickFade-appear {
  opacity: 0.0;
  z-index: 1;
}

.quickFade-appear.quickFade-appear-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.carousel {
  max-height:100%
}